import React, { useState, useEffect, Fragment } from 'react';
//import queryString from 'query-string';
import { Form, Button, Row, Col } from "react-bootstrap";
import { Route, Switch, useLocation, withRouter, useHistory } from "react-router-dom";
import LoadingCursor from '../Loading';
import Product from '../Product';
import { CommonService } from '../../services';
import API from '../../utils/API';
import Pagination from "react-js-pagination";
import { useSessionStorage } from "../useSessionStorage";
import ProductDetailModal from '../../components/ProductDetailModal';

const OemSearch = ({pCustId}) => {

    const seeEnvFileStuff = console.log("env:" + process.env.API_URL);


    const [marketId, setMarketId] = useState(process.env.MARINE_ID);
    const [custId, setCustId] = useState(pCustId);

    const [oem, setOem] = useSessionStorage("oemFilter", "");


    const itemsPerPage = 10
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useSessionStorage("pageNumberCache", 1);
    const [totalItemsCount, setTotalItemsCount] = useState(1);

    const [products, setProducts] = useState([]);
    const [distinctProducts, setdistinctProducts] = useState([]);

    const [errorMessage, setErrorMessage] = useState();

    const onOemChange = (value) => setOem(value);
    const history = useHistory();

    const [modalShow, setModalShow] = React.useState(false);
    const [modalItemId, setmodalItemId] = React.useState(0);

    const goToPage = (page) => {
        setPage(page);
        searchOem();
    }

    const openModal = (itemId) =>{
        setmodalItemId(itemId);
        setModalShow(true);
    }

    const [allInvLevels, setAllInvLevels] = useState([]);
    const getStockLevel = (custItemSku) => {
        var invRec = allInvLevels.find((element) => {
            return element.skuNumber === custItemSku;
        });

        if (invRec != null) {
            return invRec.quantity;
        } else {
            return 0;
        }
    }

    async function getProducts() {
        await API.get("/AdvancedSearch/Details/", {
            params: {
                id: 'interchange',
                oeNumber: oem,
                pageSize: itemsPerPage,
                pageNumber: page,
                marketId: marketId,
                embedType: process.env.REACT_APP_EMBED_TYPE,
                custId: custId
            }
        }).then(response => {
            if (response.status === 200) {
                setLoading(false)
                const data = response.data.Data;
                const products = []
                // eslint-disable-next-line no-lone-blocks
                {
                    data.map(item =>
                        products.push({
                            itemRow: item.productId,
                            saleItem: item.productNumber,
                            descShort: item.descShort,
                            categoryParent: item.categoryParent,
                            categoryChild: item.categoryChild,
                            imagePath: item.imagePath,
                            custItem: item.custItem
                        })
                    )
                }
                //filter the list of products to remove duplicates
                let distinctProducts = products.filter((element, index) => index === products.findIndex(elem => elem.saleItem === element.saleItem))
                
                setProducts(distinctProducts);
                const totalItems = response.data.Total;
                setTotalItemsCount(totalItems);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const searchOem = () => {
        setLoading(true);

        CommonService.getB2CInvLevels()
            .then(response => {
                if (response.status === 200) {
                    const invLevels = response.data.data;
                    setAllInvLevels(invLevels);
                    //console.log(invLevels);

                }
            }).catch(function (error) {
                console.log(error);
            });

        setProducts([]);
        getProducts();
        history.push("/results");


    }

    const submitDetailedSearch = () => {
        //Submit the Search
        setPage(1);
        searchOem();
    }

    const resetSearch = () => {
        setOem("");
       
        setPage(1);
        history.push("/");
    }

    //Reload search results from session when loaded
    useEffect(() => {
        searchOem();
    }, []);

    return (
        <Fragment>
            <div className="detailed-search-form active  p-3">
                <div className="col">
                    <Form>
                        <div className="row pb-2">
                            <div className="col-lg-2"> <span className="form-title">Find Your Parts</span></div>
                        </div>

                        <div className="row justify-content-md-start g-1">
                            <div className="col-lg-3">
                                <Form.Control type="text" name="oem" placeholder="OEM" value={oem} onChange={(e) => onOemChange(e.target.value)} isInvalid={errorMessage != null ? true : false} />
                                {errorMessage != null ? <Form.Control.Feedback type="invalid" style={{ display: 'inline' }} >{errorMessage}</Form.Control.Feedback> : null}
                            </div>
                            <div className="col-lg-auto">
                                <Button type="button" onClick={submitDetailedSearch}>SEARCH</Button>
                                <Button type="button" variant="secondary" onClick={resetSearch}>RESET</Button>
                            </div>
                        </div>
                    </Form>
                </div>


            </div>


            <Switch>

                <Route exact path="/" render={props => (

                    <img src="https://media.sierraparts.com/WidgetBanner.png"></img>

                )} />
                <Route exact path="/results" render={props => (

                    <div className="search-results oem">
                        <Fragment>

                    
                                <Fragment>
                                <div className="oem-results results">
                                        {products.length > 0 ? products.map((product, index) =>
                                            <Product
                                                key={index}
                                                description={product.descShort}
                                                categoryChild={product.categoryChild}
                                                categoryParent={product.categoryParent}
                                                saleItem={product.saleItem}
                                                productId={product.itemRow}
                                                imagePath={product.imagePath}
                                                marketId={marketId}
                                                custItem={product.custItem}
                                                itemRow={product.itemRow}
                                                stockLevel={product.custItem ? getStockLevel(product.custItem) : 0}
                                                showModal={(id) => openModal(id)}
                                                pCustId={pCustId}
                                            />
                                    ) : <p className="lead p-3">No results found for this search.</p>}

                                        {totalItemsCount > itemsPerPage ?
                                            <div className="text-center mt-2">
                                                <Pagination
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    activePage={page}
                                                    itemsCountPerPage={itemsPerPage}
                                                    totalItemsCount={totalItemsCount}
                                                    pageRangeDisplayed={5}
                                                    onChange={goToPage}
                                                />
                                            </div>
                                            : null}
                                    <div className=''>
                                        <ProductDetailModal modalItemId={modalItemId} show={modalShow} closeModal={() => setModalShow(false)} onHide={() => setModalShow(false)}></ProductDetailModal>
                                    </div>
                                </div>
                                
                                </Fragment>
                   

                        </Fragment>
                    </div>

                )} />

               
            </Switch>

          

        </Fragment>
    )
}

export default OemSearch;