/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment } from 'react'
import { Link } from "react-router-dom";
import { LoadingCursor } from '../Loading';
import { CommonService } from '../../services/index';
import Pagination from "react-js-pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';


const ApplicationWidget = (props) => {

    const productId = props.sku;//4939;
    const itemsPerPage = 10;

    const [applicationInformationCategories, setApplicationInformationCategories] = useState([]);
    const [page, setPage] = useState(1);
    const [pagedParts, setPagedParts] = useState([]);
    const [numberOfParts, setNumberOfParts] = useState(0);
    const [filterString, setFilterString] = useState('');
    const [activeBrand, setActiveBrand] = useState(false);
    const [showResults, setShowResults] = useState(false)

    const getApplicationInformationBrands = () => {
        CommonService.getApplicationInformationBrands(productId)
            .then(response => {
                if (response.status === 200) {
                    //setLoadingCategories(false)
                    let tmpAIC = [];
                    let total = 0;
                    response.data.Data.map((item, index) => {
                        total = total + item.itemCount;
                    });
                    tmpAIC.push({
                        itemCount: total,
                        brandId: false,
                        brand: "All Manufacturers"
                    })
                    tmpAIC = tmpAIC.concat(response.data.Data);
                    setApplicationInformationCategories(tmpAIC);
                    setActiveBrand(tmpAIC[0].brandId)
                    getApplicationInformationParts(productId, activeBrand, filterString, page, itemsPerPage);

                    if (total > 0) {
                        setShowResults(true);
                    } else {
                        setShowResults(false);
                    }
                }
            }).catch(function (error) {
                console.log(error);
            });
    }

    const getApplicationInformationParts = (productId, brand, searchFilter, page, itemsPerPage) => {
        CommonService.getApplicationInformationParts(productId, brand, searchFilter, page, itemsPerPage)
            .then(response => {
                if (response.status === 200) {
                    console.log(response.data)
                    //setLoadingParts(false)
                    setPagedParts(response.data.Data)
                    setNumberOfParts(response.data.Data[0].totalRows)
                }
            }).catch(function (error) {
                console.log(error);
            });
        setActiveBrand(brand);
    }

    const goToPage = (page) => {
        setPage(page);
        getApplicationInformationParts(productId, activeBrand, filterString, page, itemsPerPage);
    }

    const filterByCategory = (brandId) => {
        setPage(1);
        setActiveBrand(brandId);
        getApplicationInformationParts(productId, brandId, filterString, page, itemsPerPage);
    }

    useEffect(() => {
        const timeOutId = setTimeout(() => getApplicationInformationParts(productId, activeBrand, filterString, page, itemsPerPage), 500);
        return () => clearTimeout(timeOutId);
    }, [filterString]);

    useEffect(() => {
        getApplicationInformationBrands();
    }, []);

    const Results = () => (
        <div className="container">
            <div className="application-information">
                <div className="title">
                    <h2>Application Information</h2>
                </div>
                <div className="row flex">
                    <div className="col-sm-3 offset-lg-1">
                        <aside>

                            <Fragment>
                                <ul>
                                    {applicationInformationCategories.length > 0 ?
                                        applicationInformationCategories.map((aiCategory, cindex) =>
                                            <li key={cindex} className={activeBrand == aiCategory.brandId ? "active-ap-in" : ""}>
                                                <button href="#" onClick={() => filterByCategory(aiCategory.brandId)}>
                                                    <span className="text-left">{aiCategory.brand}</span>
                                                </button>
                                                <span>{aiCategory.itemCount}</span>
                                            </li>
                                        ) : null}
                                </ul>
                            </Fragment>

                        </aside>
                    </div>
                    <div className="col-sm-9 col-lg-7">

                        <Fragment>
                            <div className="filter-input mt-2">
                                <input className="form-control" type="text" name="filter" placeholder="Filter Engines" value={filterString} onChange={event => {
                                    setFilterString(event.target.value);
                                }} />
                            </div>
                            <div className="results ai-results">
                                <div className="theading">
                                    <div className="row">
                                        <div className="col model-name">
                                            <span>MODEL/ Manufacturer</span>
                                        </div>
                                        <div className="col years-range">
                                            <span>YEAR</span>
                                        </div>
                                        <div className="col icon">
                                            <span>HP</span>
                                        </div>
                                        <div className="col serials-range">
                                            <span>Serial range</span>
                                        </div>
                                        <div className="col icon"></div>
                                    </div>
                                </div>
                                {pagedParts.length > 0 ? pagedParts.map((part, index) =>
                                    <span key={index}
                                        className="result-item simple">
                                        <div className="row">
                                            <div className="col model-name">
                                                <strong>{part.ModelNum}</strong>
                                            </div>
                                            <div className="col years-range">
                                                <span>{part.Start}-{part.Stop}</span>
                                            </div>
                                            <div className="col icon">
                                                <span>{part.HP}</span>
                                            </div>
                                            <div className="col serials-range">
                                                <span>{part.StartSerialSearch} THRU {part.StopSerialSearch}</span>
                                            </div>
                                            {/*<div className="col icon">*/}
                                            {/*    <FontAwesomeIcon icon={faAngleRight} className="go-to-category" />*/}
                                            {/*</div>*/}
                                        </div>
                                        {part.Note ?
                                            <div className="row">
                                                <div className="col-sm-12 text-center">
                                                    <em>{part.Note}</em>
                                                </div>
                                            </div>
                                            : null}
                                    </span>
                                ) : null}
                            </div>
                            {numberOfParts > itemsPerPage ? (
                                <div className="text-center mt-2">
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={page}
                                        itemsCountPerPage={itemsPerPage}
                                        totalItemsCount={numberOfParts}
                                        pageRangeDisplayed={5}
                                        onChange={goToPage}
                                    />
                                </div>
                            ) : null}
                        </Fragment>

                    </div>
                </div>
            </div>
        </div>
    )

    return (
      
        <Fragment>
            {showResults ? <Results /> : null}
        </Fragment>
    )


   

}

export default ApplicationWidget;