import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Form, Button } from "react-bootstrap";
import BrandDropdown from './BrandDropdown';
import EngineResults from './EngineResults';
import EngineDetail from './OldEngineDetails';
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import { useSessionStorage } from "../useSessionStorage";

const EngineSearch = (props) => {
    console.log("EngineSearch");
    //const seeEnvFileStuff = console.log("env:" + process.env.API_URL); //Debug
    const itemsPerPage = 10

    const [custId, setCustId] = useState(props.pCustId);
    
    const [sessionPage, setSessionPage] = useSessionStorage("pageNumberCache", 1);

    const [page, setPage] = useState(sessionPage);


    const location = useLocation();
    //console.log(location.pathname); //Debug

    const history = useHistory();

    const [marketId, setMarketId] = useState(process.env.MARINE_ID);

    const currentYear = new Date().getFullYear();
    const startYear = 1951;
    const years = Array(currentYear - startYear).fill().map((d, i) => currentYear - i);
    const categories = Array('Marine', 'Garden', 'Powersports');

    const [sessionCategory, setsessionCategory] = useSessionStorage("categoryFilter", "Marine");
    const [sessionBrand, setsessionBrand] = useSessionStorage("brandFilter", "");
    const [sessionModelNumber, setsessionModelNumber] = useSessionStorage("modelNumberFilter", "");
    const [sessionYear, setsessionYear] = useSessionStorage("yearFilter", "");
    const [sessionHp, setsessionHp] = useSessionStorage("hpFilter", "");
    const [sessionSn, setsessionSn] = useSessionStorage("snFilter", "");

    const [category, setCategory] = useState(sessionCategory);
    const [brand, setBrand] = useState(sessionBrand);
    const [modelNumber, setModelNumber] = useState(sessionModelNumber);
    const [year, setYear] = useState(sessionYear);
    const [hp, setHp] = useState(sessionHp);
    const [sn, setSn] = useState(sessionSn);

    const [errorMessage, setErrorMessage] = useState();
    
    //On Change Events
    const onCategoryChange = (value) => { 
        setsessionCategory(value)
        switch(value){
        case 'Marine':
            return setMarketId(process.env.MARINE_ID);
        case 'Garden':
            return setMarketId(process.env.GARDEN_ID);
        case 'Powersports':
            return setMarketId(process.env.POWERSPORTS_ID);
        default:
            return setMarketId(process.env.MARINE_ID);
        }
        
    }

    const onBrandChange = (value) => { setsessionBrand(value); }
    const onModelNumberChange = (value) => { setsessionModelNumber(value); }
    const onYearChange = (value) => { setsessionYear(value); }
    const onHpChange = (value) => { setsessionHp(value); }
    const onSnChange = (value) => { setsessionSn(value); }

    const resetSearchMethod = () => {

        onCategoryChange('Marine');

        setBrand("");
        setsessionBrand("");

        setModelNumber("");
        setsessionModelNumber("")

        setYear("");
        setsessionYear("");

        setHp("");
        setsessionHp("");

        setSn("");
        setsessionSn("");

        resetPage();
        history.push("/");
    }

    const changePage = (page) => {
        setPage(page);
        setSessionPage(page);
    }

    const resetPage = () => {
        setPage(1);
        setSessionPage(1);
    }

    const submitSearch = () => {

        //On Change Events
        setCategory(sessionCategory);
        setBrand(sessionBrand);
        setModelNumber(sessionModelNumber);
        setYear(sessionYear);
        setHp(sessionHp);
        setSn(sessionSn);
        resetPage();
        history.push("/results");
    }

    useEffect(() => {
        window.onbeforeunload = function() {
            setCategory("Marine");
            setsessionCategory("Marine");
        }
    });

    

    return (
        <Fragment>
            <div className="detailed-search-form active p-3">
                <div className="col">
                    <Form>
                        <div className="row pb-2">
                            <div className="col-lg-2"> <span className="form-title">Find Your Parts</span></div>
                        </div>
                        <div className="row justify-content-md-start g-1">
                            <div className="col-lg-1">
                                <Form.Control as="select" name="Category" onChange={(e) => onCategoryChange(e.target.value)} value={sessionCategory}>
                                    <option value="category" >Category</option>
                                    {categories.map((item, index) =>
                                        <option value={item} key={index}>{item}</option>
                                    )}
                                </Form.Control>
                                </div>
                                <div className="col-lg-auto">
                                <BrandDropdown marketId={marketId} brandP={sessionBrand} onChange={onBrandChange} errorMessage={errorMessage} />
                            </div>
                            <div className="col-lg-auto">
                                <Form.Control type="text" name="modelNumber" placeholder="Model" value={sessionModelNumber} onChange={(e) => onModelNumberChange(e.target.value)} />
                            </div>
                            <div className="col-lg-1">
                                <Form.Control as="select" name="year" onChange={(e) => onYearChange(e.target.value)} value={sessionYear}>
                                    <option value="">Year</option>
                                    {years.map((item, index) =>
                                        // eslint-disable-next-line eqeqeq
                                        <option value={item} key={index}>{item}</option>
                                    )}
                                </Form.Control>
                            </div>
                            <div className="col-lg-1">
                                <Form.Control type="text" name="hp" placeholder="HP" value={sessionHp} onChange={(e) => onHpChange(e.target.value)} />
                            </div>
                            <div className="col-lg-auto">
                                <Form.Control type="text" name="sn" placeholder="Serial Number" value={sessionSn} onChange={(e) => onSnChange(e.target.value)} />
                            </div>
                            <div className="col-lg-auto">
                                <Button type="button" onClick={submitSearch}>SEARCH</Button>

                                <Button type="button" variant="secondary" onClick={resetSearchMethod}>RESET</Button>
                            </div>
                        </div>



                    </Form>
                </div>
           

            </div>


            <Switch pCustId={custId}>

                <Route exact path="/" render={props => (

                    <img src="https://media.sierraparts.com/WidgetBanner.png"></img>

                )} />
                <Route exact path="/results" render={props => (

                    <EngineResults {...props}
                        pageChange={changePage}
                        itemsPerPage={itemsPerPage}
                        marketId={marketId}
                        currentPage={page}
                        brand={brand}
                        modelNumber={modelNumber}
                        year={year}
                        hp={hp}
                        sn={sn}
                        pCustId={custId}
                    />

                )} />

                <Route path="/engine/:engineId" render={props => (<EngineDetail {...props} pCustId={custId} />)} />
            </Switch>

        </Fragment>
    )
}


export default EngineSearch;