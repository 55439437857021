import React from 'react'

const LoadingCursor = (props) => {
    return (
        <div className="text-center loading" >
            <div className="spinner-border" >
                <span className="sr-only" ></span>
            </div>
        </div>
    )
}

export default LoadingCursor;