/* eslint-disable no-unused-vars */
import { Fragment, useState, useEffect } from 'react';

import { Tabs, Tab } from 'react-bootstrap';
import { CommonService } from '../services';

const ProductDetailPage = ({ productId }) => {
    console.log("ProductDetailPage");

    //const [ProductId, setProductId ] = useState(null);
    const [Product, setProduct] = useState(null);
    const [ProductSpecs, setProductSpecs] = useState(null);
    const [InterchangeDetails, setInterchangeDetails] = useState(null);
    const [CustomerItem, setCustomerItem] = useState(null);

    useEffect(() => {
        getDetail(productId);

    }, [productId]);

    const getBaseURL = () => {
        console.log("getBaseURL:", location);
        //console.log("url: ", location.origin + location.pathname);
        return location.origin + location.pathname;
    }

    const getInterchangeDetails = (interchangeArray) => {
        let interchangeBrand = "";
        let interchangeDetails = "";
        setInterchangeDetails("");

        for (let i = 0; i < interchangeArray.length - 1; i++) {
            if (interchangeBrand == interchangeArray[i]?.brandName) {
                interchangeDetails = interchangeDetails + <table className='table table-sm'>;
                    interchangeDetails = interchangeDetails + <tbody><tr><td className=''> + interchangeArray[i]?.oeNumber + </td></tr></tbody>;
                    interchangeDetails = interchangeDetails + </table>;
            }
            else {
                interchangeBrand = interchangeArray[i]?.brandName;
                interchangeDetails = interchangeDetails + "<p className='lead'> + interchangeBrand + </p>";
            }
        }

        setInterchangeDetails(interchangeDetails);
    }

    const getDetail = (productId) => {
        CommonService.getProductDetails(productId)
            .then(response => {
                if (response.status === 200) {
                    //debugger;
                    const _item = response.data.Data[0];
                    console.log(_item);
                    setProduct(_item);
                    getDetailSpecs(_item.itemRow);
                    getItemAdditionalInfo(_item.itemRow);
                }
            }).catch(function (error) {
                console.log(error);
            });
    }

    const getItemAdditionalInfo = (productId) => {
        CommonService.getItemAdditionalInfo(productId, "711442")
            .then(response => {
                if (response.status === 200) {
                    //debugger;
                    const _itemInfo = response.data.data;
                    if (_itemInfo !== null)
                        setCustomerItem(_itemInfo.custItem);
                }
            }).catch(function (error) {
                console.log(error);
            });
    }

    const getDetailSpecs = (itemRow) => {
        CommonService.getProductDetailSpecs(itemRow)
            .then(response => {
                if (response.status === 200) {
                    //debugger;
                    const _detail = response.data.Data[0];
                    console.log(_detail);
                    //getInterchangeDetails(_detail.interchangeDetails);
                    setProductSpecs(_detail);
                }
            }).catch(function (error) {
                console.log(error);
            });
    }

    return (
        <Fragment>

            <title>
                {Product?.sale_item} - {Product?.descriptionShort}
            </title>
            <div className="col-12 py-1">
                <p>
                    <a className='btn btn-sm btn-primary' href={getBaseURL()}>Return to Parts Finder</a>
                </p>
                <div className="card p-3">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-6">
                                <table className="">
                                    <tbody>
                                        <tr>
                                            <td><span className="text-muted">Item:</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>{Product?.sale_item}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="text-muted">Description:</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>{Product?.descriptionShort}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="text-muted">Category:</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>{Product?.categoryParent} / {Product?.categoryChild}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>
                                    <br />
                                    {CustomerItem ? (
                                        <a href={"/sku/" + CustomerItem} className="btn btn-sm btn-primary">
                                            Buy Online
                                        </a>
                                    ) : (
                                        <a href="https://www.sierraparts.com/en-us/find-a-dealer" className="btn btn-sm btn-success">
                                            Dealer Locater
                                        </a>
                                    )}
                                </p>
                            </div>
                            <div className="col-6">
                                <div>
                                    <div tabIndex="-1">
                                        <img
                                            src={"https://media.sierraparts.com/productImages/product/tr:h-200/" + Product?.imageDetails[0].fileName}
                                            alt={Product?.imageDetails[0].fileName} />
                                    </div>
                                </div>

                            </div>

                        </div>

                        <p className="small text-muted">This part is available from one of our preferred dealers.</p>
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <Tabs defaultActiveKey="details" id="uncontrolled-tab-example">
                                    <Tab eventKey="details" title="Details">
                                        {ProductSpecs?.productDetails.map((item, index) =>
                                            item.strongValue === true ? <p key={index} ><strong>{item.attribute !== '_WARNING' ? item.attribute + ":" : ""} {item.attributeValue}</strong></p>
                                                : <p key={index} >{item.attribute !== '_FEATURE' ? item.attribute + ":" : ""} {item.attributeValue}</p>
                                        )}
                                    </Tab>
                                    <Tab eventKey="specs" title="Specs">
                                        <div className="small row">
                                            <div className="small col-sm-6">
                                                <p className="lead text-center">Packaging Dimensions</p>
                                                <table className="table table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td className="">Weight</td>
                                                            <td>{ProductSpecs?.productSpecs[0].weight}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="">Height</td>
                                                            <td>{ProductSpecs?.productSpecs[0].height}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="">Depth</td>
                                                            <td>{ProductSpecs?.productSpecs[0].depth}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="">Width</td>
                                                            <td>{ProductSpecs?.productSpecs[0].width}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="small col-sm-6">
                                                <p className="lead text-center">Superceded</p>
                                                <table className="table table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td className="">Unit of measure</td>
                                                            <td>{ProductSpecs?.productSpecs[0].um}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="">Item status</td>
                                                            <td>{ProductSpecs?.productSpecs[0].itemStatus}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="">UPC</td>
                                                            <td>{ProductSpecs?.productSpecs[0].upc}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="">Case packaging</td>
                                                            <td>{ProductSpecs?.productSpecs[0].case_packaging}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="">Standard packaging</td>
                                                            <td>{ProductSpecs?.productSpecs[0].standard_packaging}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="">HSC</td>
                                                            <td>{ProductSpecs?.productSpecs[0].hsc}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="interchange" title="Interchange">
                                        <div className="row">
                                            <div className="col-lg-6">

                                                <table className='table table-sm'>
                                                    <tbody>
                                                        {ProductSpecs?.interchangeDetails.map((object, i) => {
                                                            return <tr key={i}>
                                                                <td className="pe-3">{ProductSpecs.interchangeDetails[i]?.brandName}</td>
                                                                <td className="ps-3">{ProductSpecs.interchangeDetails[i]?.oeNumber}</td>
                                                            </tr>
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default ProductDetailPage;