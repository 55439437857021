import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import EngineCommon from './EngineCommon';
import { Link, withRouter } from "react-router-dom";

const EngineResult = ({brandName, modelNumber, engineId, startYear, stopYear, horsepower, stroke, cylinders, marketId}) => {
    const engineUrl = "/engine/" + engineId;

    return (
        <Link to={engineUrl} className="result-item" >
            <div className="row">
                <EngineCommon 
                    brandName={brandName}
                    modelNumber={modelNumber}
                    startYear={startYear}
                    stopYear={stopYear}
                    horsepower={horsepower}
                    stroke={stroke}
                    cylinders={cylinders}
                />
                <div className="result-link">
                    <FontAwesomeIcon icon={faAngleRight} className="go-to-category" />
                </div>
            </div>
        </Link>
    )
}

export default EngineResult;