import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import SearchHome from './components/SearchHome';
import ProductDetailPage from './components/ProductDetailPage';

const BrandSearch = (props) => {
    console.log("BrandSearch");

    const history = useHistory();
    const [custId, setCustId] = useState(props.custid);
    const [ProductId, setProductId] = useState(null);

    useEffect(() => {
        return history.listen((location) => {
            sessionStorage.setItem("BrandSearchWidgetPath", location.pathname);
        })
    }, [history]);

    useEffect(() => {
        // call api or anything
        var result = sessionStorage.getItem("BrandSearchWidgetPath");
        if (result != null) {
            history.push(result);
        }
        //console.log(location)
    });

    useEffect(() => {
        let search = location.search;
        let params = new URLSearchParams(search);

        setProductId(params.get("productId"))
        console.log("ProductId: ", ProductId);
        
    });

    return (
        <div>
        {(() => {
          if (ProductId) {
            return <ProductDetailPage productId={ProductId} />;
          } else {
            return <SearchHome pCustId={custId} />;
          }
        })()}
      </div>
    )
    
}

export default BrandSearch;