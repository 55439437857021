import React, { useState, useEffect, Fragment } from 'react';
import { useSessionStorage } from "../useSessionStorage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import EngineCommon from './EngineCommon';
import EngineResult from './EngineResult';
import { Link, withRouterm, useLocation, withRouter } from "react-router-dom";
import API from '../../utils/API';
import Pagination from "react-js-pagination";
import Loading from '../Loading';

const EngineResultSet = (props) => {

    const [loading, setLoading] = useState(false);

    const [totalItemsCount, setTotalItemsCount] = useState(1);
    const [engines, setEngines] = useState([]);
   
    async function getEnginesWParams() {

        console.log("searchEngines");



        await API.get("/AdvancedSearch/Details/", {
            params: {
                id: 'brandmodel',
                brand: props.brand,
                modelNumber: props.modelNumber,
                year: props.year,
                hp: props.hp,
                serialNumber: props.sn,
                queryType: 'contains',
                marketId: props.marketId,
                embedType: process.env.EMBED_TYPE,
                custId: props.pCustId,
                pageSize: props.itemsPerPage,
                pageNumber: props.currentPage
            }
        }).then(response => {
            if (response.status === 200) {
                const data = response.data.Data;
                setLoading(false)
                setEngines(data);
                setTotalItemsCount(1);
                if (data.length > 0) {
                    const totalItems = data[0].totalRows;
                    setTotalItemsCount(totalItems);
                }
                
            }
            
        }).catch(function (error) {
            console.log(error);
        });
    }


    const searchEnginesWValues = () => {
        setLoading(true);
        setEngines([]);
        getEnginesWParams();
    }

    const goToPage = (page) => {
        console.log("goToPage:" + page);
        props.pageChange(page);
        searchEnginesWValues();
    }

    useEffect(() => {
        searchEnginesWValues();
    }, []);

    useEffect(() => {
        getEnginesWParams();
    }, [props.brand, props.modelNumber, props.year, props.hp, props.sn]);

    return (
        <Fragment>
            <div className="search-results">
                <div className="results engine-results">
                    <div>
                        {engines.length > 0 ? engines.map((engine, index) =>
                        <EngineResult
                                key={index}
                                horsePower={engine.horsepower}
                                brandName={engine.brandName}
                                modelNumber={engine.modelNumber}
                                engineId={engine.engineId}
                                startYear={engine.startYear}
                                stopYear={engine.stopYear}
                                horsepower={engine.horsepower}
                                stroke={engine.stroke}
                                cylinders={engine.cylinders}
                                startSerialNumber={engine.startSerialNumber}
                                stopSerialNumber={engine.stopSerialNumber}
                                marketId={props.marketId}
                                custId={props.pCustId}
                        />
                        ) : <p className="lead p-3">No results found for this search.</p>}
                    </div>
                   
       
                    {totalItemsCount > props.itemsPerPage ?
                        <div className="text-center mt-2">
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={props.currentPage}
                                itemsCountPerPage={props.itemsPerPage}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={10}
                                onChange={goToPage}
                            />
                        </div>
                        : null}
                </div>
            </div>
        </Fragment>
    )
}

export default EngineResultSet;