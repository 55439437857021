import React, { Fragment } from 'react';



const EngineCommon = ({brandName, modelNumber, startYear, stopYear, horsepower, stroke, cylinders}) => {
    return (
        <Fragment>
        <div className="result-description">
            <h4>{brandName}</h4>
            <span>{modelNumber}</span>
        </div>
        <div className="result-year">{startYear}{startYear && stopYear ? "-" : null}{stopYear}</div>
        <div className="result-info">
            {horsepower ? <span className="saleItem" style={{display: 'block'}} >HP: {horsepower}</span> : null}
            {stroke ? <span className="saleItem" style={{display: 'block'}} >Stroke: {stroke}</span> : null}
            {cylinders ? <span className="saleItem" style={{display: 'block'}}>Cylinders: {cylinders}</span> : null}
        </div>
        </Fragment>
    )
}


export default EngineCommon;