import React from 'react';
import { render } from 'react-dom';
import BrandSearch from './BrandSearch';
import { MemoryRouter, Route } from "react-router-dom";
import { createBrowserHistory } from 'history';

var brandSearchContainer = document.getElementById('BrandSearch');

if (brandSearchContainer != null) {
        render(
            <MemoryRouter basename="/en-us/sonar-widget" history={createBrowserHistory}>
                <BrandSearch {...(brandSearchContainer.dataset)} />
            </MemoryRouter>,
            brandSearchContainer,
        );
}


