import React, { useState, Fragment } from 'react';
import { Form, Row, Col } from "react-bootstrap";
import EngineSearch from './engine/EngineSearch';
import OemSearch from './oem/OemSearch';
import { useSessionStorage } from "./useSessionStorage";

const SearchHome = ({pCustId}) => {
    console.log("SearchHome");

    const [searchMode, setSearchMode] = useSessionStorage("searchMode", "model");
    const [custId, setCustId] = useState(pCustId);

    const handleSearchByModel = () => {
        setSearchMode("model");
    }
    const handleSearchByOem = () => {
        setSearchMode("oem");
    }

    return (
        <Fragment>
            <Form.Group as={Row} className="filter-dc">
                <Col>
                    <Form.Check
                        type="radio"
                        label="Model"
                        name="search_type"
                        id="model"
                        checked={searchMode == "model" ? "checked" : ""}
                        onChange={handleSearchByModel}
                        inline
                    />
                    <Form.Check
                        type="radio"
                        label="OEM/Competitive Part #"
                        name="search_type"
                        id="oem"
                        checked={searchMode == "oem" ? "checked" : ""}
                        onChange={handleSearchByOem}
                        inline
                    />
                </Col>
            </Form.Group>

            {searchMode == "model" ? <EngineSearch pCustId={custId} /> : null}
            {searchMode == "oem" ? <OemSearch pCustId={custId} /> : null}
        </Fragment>
    )
}
export default SearchHome;