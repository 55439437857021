import { Fragment, useRef, useState, ModalManager } from 'react';

import Modal from 'react-bootstrap/Modal';
import { Button, Tabs, Tab, View } from 'react-bootstrap';
import { CommonService } from '../services';

const ProductDetailModal = (props) => {
    const containerRef = document.getElementById("sp-widget-embed");
    const [modalItem, setModalItem] = useState(null);
    const [modalItemSpecs, setModalItemSpecs] = useState(null);

    const [InterchangeDetails, setInterchangeDetails] = useState(null);

    const getInterchangeDetails = (interchangeArray) =>{
        let interchangeBrand = "";
        let interchangeDetails = "";
        setInterchangeDetails("");

        for (let i = 0; i < interchangeArray.length-1; i++) {
            if (interchangeBrand==interchangeArray[i]?.brandName)
            {
                interchangeDetails = interchangeDetails + <table className='table table-sm'>;
                interchangeDetails = interchangeDetails + <tbody><tr><td className=''> + interchangeArray[i]?.oeNumber + </td></tr></tbody>;
                interchangeDetails = interchangeDetails + </table>;
            }
            else{
                interchangeBrand = interchangeArray[i]?.brandName;
                interchangeDetails = interchangeDetails + "<p className='lead'> + interchangeBrand + </p>";
            }
        }

        setInterchangeDetails(interchangeDetails);  
    }

    const getDetail = (itemId) =>{
        CommonService.getProductDetails(itemId)
        .then(response => {
            if (response.status === 200) {
                //debugger;
                const _item = response.data.Data[0];
                console.log(_item);
                setModalItem(_item);
                getDetailSpecs(_item.itemRow);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getDetailSpecs = (itemRow) =>{
        CommonService.getProductDetailSpecs(itemRow)
        .then(response => {
            if (response.status === 200) {
                //debugger;
                const _detail = response.data.Data[0];
                console.log(_detail);
                //getInterchangeDetails(_detail.interchangeDetails);
                setModalItemSpecs(_detail);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

  return (

    <Fragment>
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='productDetailModal'
            container={containerRef}
            onHide={props.onHide}
            onShow={()=>getDetail(props.modalItemId)}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {modalItem?.sale_item} - {modalItem?.descriptionShort}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <div className="col-12 py-1">
                <div className="card p-3">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-6">
                                <table className="">
                                    <tbody>
                                        <tr>
                                            <td><span className="text-muted">Item:</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>{modalItem?.sale_item}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="text-muted">Description:</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>{modalItem?.descriptionShort}</span></td>
                                        </tr>
                                        <tr>
                                            <td><span className="text-muted">Category:</span></td>
                                        </tr>
                                        <tr>
                                            <td><span>{modalItem?.categoryParent} / {modalItem?.categoryChild}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-6">
                                <div>
                                    <div tabIndex="-1">
                                        <img
                                            src={"https://media.sierraparts.com/productImages/product/tr:h-200/"+modalItem?.imageDetails[0].fileName}
                                            alt={modalItem?.imageDetails[0].fileName} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="small text-muted">This part is available from one of our preferred dealers.</p>
                              <div className="row">
                                  <div className="col-sm-12 col-md-12">
                                      <Tabs defaultActiveKey="details" id="uncontrolled-tab-example">
                                        <Tab eventKey="details" title="Details">
                                            {modalItemSpecs?.productDetails.map((item, index) =>
                                                item.strongValue === true ? <p key={index} ><strong>{item.attribute !== '_WARNING' ? item.attribute + ":" : ""} {item.attributeValue}</strong></p>
                                                    : <p key={index} >{item.attribute !== '_FEATURE' ? item.attribute + ":" : ""} {item.attributeValue}</p>
                                            )}
                                        </Tab>
                                          <Tab eventKey="specs" title="Specs">
                                              <div className="small row">
                                                  <div className="small col-sm-6">
                                                      <p className="lead text-center">Packaging Dimensions</p>
                                                      <table className="table table-sm">
                                                          <tbody>
                                                              <tr>
                                                                  <td className="">Weight</td>
                                                                  <td>{modalItemSpecs?.productSpecs[0].weight}</td>
                                                              </tr>
                                                              <tr>
                                                                  <td className="">Height</td>
                                                                  <td>{modalItemSpecs?.productSpecs[0].height}</td>
                                                              </tr>
                                                              <tr>
                                                                  <td className="">Depth</td>
                                                                  <td>{modalItemSpecs?.productSpecs[0].depth}</td>
                                                              </tr>
                                                              <tr>
                                                                  <td className="">Width</td>
                                                                  <td>{modalItemSpecs?.productSpecs[0].width}</td>
                                                              </tr>
                                                          </tbody>
                                                      </table>
                                                  </div>
                                                  <div className="small col-sm-6">
                                                      <p className="lead text-center">Superceded</p>
                                                      <table className="table table-sm">
                                                          <tbody>
                                                              <tr>
                                                                  <td className="">Unit of measure</td>
                                                                  <td>{modalItemSpecs?.productSpecs[0].um}</td>
                                                              </tr>
                                                              <tr>
                                                                  <td className="">Item status</td>
                                                                  <td>{modalItemSpecs?.productSpecs[0].itemStatus}</td>
                                                              </tr>
                                                              <tr>
                                                                  <td className="">UPC</td>
                                                                  <td>{modalItemSpecs?.productSpecs[0].upc}</td>
                                                              </tr>
                                                              <tr>
                                                                  <td className="">Case packaging</td>
                                                                  <td>{modalItemSpecs?.productSpecs[0].case_packaging}</td>
                                                              </tr>
                                                              <tr>
                                                                  <td className="">Standard packaging</td>
                                                                  <td>{modalItemSpecs?.productSpecs[0].standard_packaging}</td>
                                                              </tr>
                                                              <tr>
                                                                  <td className="">HSC</td>
                                                                  <td>{modalItemSpecs?.productSpecs[0].hsc}</td>
                                                              </tr>
                                                          </tbody>
                                                      </table>
                                                  </div>
                                              </div>
                                          </Tab>
                                          <Tab eventKey="interchange" title="Interchange">
                                              <div className="row">
                                                  <div className="col-lg-6">
                                                        
                                                        <table className='table table-sm'>
                                                            <tbody>
                                                                {modalItemSpecs?.interchangeDetails.map((object,i)=>
                                                                {
                                                                        return <tr>
                                                                        <td className="pe-3">{modalItemSpecs.interchangeDetails[i]?.brandName}</td>
                                                                        <td className="ps-3">{modalItemSpecs.interchangeDetails[i]?.oeNumber}</td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                  </div>
                                              </div>
                                          </Tab>
                                      </Tabs>

                                  </div>
                              </div>
                    </div>
                </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="float-end">
                        <Button onClick={props.closeModal} className="btn btn-secondary">Close</Button>&nbsp;&nbsp;&nbsp;
                </div>
            </Modal.Footer>
        </Modal>
    </Fragment>
  );
}

export default ProductDetailModal;