import React, { useEffect, useState} from 'react';

import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

const Product = (props) => {

    let productUrl = "/sku/" + props.custItem;
    let catalogUrl = "https://catalog.sierraparts.com/product/" + props.itemRow;

    const [showModal, setShowModal] = useState(false);

    const openModal = (itemId) =>{
        props.showModal(itemId);
    }
 
    const history = useHistory();

    useEffect(() => {
        const _ids = process.env.CustIdMoreInfoModal.split(",");
        setShowModal(_ids.indexOf(props.pCustId)>=0)
        console.log("id:", _ids.indexOf(props.pCustId));
        console.log("showModal:", showModal);
    },[props.pCustId]);


    //console.log("here")
    //console.log("ids:", _ids);
    //process.env.CustIdMoreInfo.contains.find((c)=> c.id===props.pCustId) &&
    const displayButtons = () =>
    {
            
            return <div className="d-flex flex-row flex-wrap justify-content-center">
                {  showModal &&
                <div>
                    <a href="https://www.sierraparts.com/en-us/find-a-dealer" className="btn btn-sm btn-success rounded-pill border">Dealer Locator</a>
                </div>
                }
                <div className='ps-2'>
                    <a onClick={() => { showModal ? openModal(props.itemRow) : window.open(catalogUrl, '_blank') }} className="btn btn-sm btn-light rounded-pill border">Read More</a>
                </div>
            </div>
    }

    return (
        <div className="result-item" key={props.key}>
            <div className="row">
                <div className="result-image">
                    <figure>
                        {props.imagePath ? <img src={process.env.IMAGE_PATH + "/tr:w-70/" + props.imagePath} alt={props.description} /> : null}
                    </figure>
                </div>
                <div className="result-id">
                    <span className="saleItem" title={props.custItem ? props.custItem : props.saleItem}>{props.saleItem}</span>
                </div>
                <div className="result-description">
                    <h4>{props.description}</h4>
                    <span>{props.categoryParent}</span> / <span>{props.categoryChild}</span>
                    {props.oeNumber ? <Fragment><br /><span>OEM part: {props.oeNumber}</span></Fragment> : null}

                    {props.listPrice ?
                        <div className="product-price">${props.listPrice} MSRP</div>
                        : null}
                   
                </div>
                <div className="result-link">
                    <div className="row justify-content-end">

                        {props.custItem && props.stockLevel && props.stockLevel > 10 ?
                            <div className="col-sm-auto stock-message fw-light">
                                <span className="in-stock-dot"></span> In Stock
                            </div> : null}

                        {props.custItem && props.stockLevel && props.stockLevel <= 10 && props.stockLevel > 0 ?
                            <div className="col-sm-auto stock-message fw-light">
                                <span className="low-stock-dot"></span> Low Stock
                            </div> : null}

                        {props.custItem && props.stockLevel && props.stockLevel <= 0 ?
                            <div className="col-sm-auto stock-message fw-light">
                                <span className="empty-stock-dot"></span> Out of Stock
                            </div> : null}
 

                        <div className="col-sm-auto">
                            
                            {props.custItem ?
                                <a href={productUrl} className="btn btn-sm btn-primary rounded-pill">Buy Online</a>
                                :
                                displayButtons()
                            }
                          
                        </div>
                    </div>


                </div>

            </div>
        </div>
    )
}



export default Product;