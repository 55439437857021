/* eslint-disable import/no-anonymous-default-export */
import API from '../utils/API';
import NewAPI from '../utils/NewAPI';

export default {
    //TODO: add custId back in for future customers
    async getMakes(marketId) {
        return API.get("/AdvancedSearch/Details/?id=getbrands", {
            params: {
                marketid: marketId,
                //custId: '711442'
            }
        })
    },

    async getProductDetails(productId) {
        return await API.get("/SierraPartSearch/Details/" + productId);
    },
    async getProductDetailSpecs(itemRow) {
        return await API.get("/SierraPartPartialSearch/Details/?itemRow=" + itemRow);
    },
    async getProductAdditionalInformation(productId, filterAnalytics, filterAnalyticsCategoryId, filterAnalyticsMarketId) {
        return await API.get("/SierraPartPartialSearch/Details/?itemRow=" + productId
            + (filterAnalytics != null ? filterAnalytics : "")
            + (filterAnalyticsCategoryId != null ? "&filterSetDetails.categoryId=" + filterAnalyticsCategoryId : "")
            + (filterAnalyticsMarketId != null ? "&filterSetDetails.marketId=" + filterAnalyticsMarketId : "")
        );
    },

    async getEngineDetails(engineId) {
        return await API.get("/EngineDetails/Details/" + engineId);
    },



    async getCategoryFilters(categoryName, marketId) {
        return await API.get("/IconFilters/Details/", {
            params: {
                query: categoryName,
                marketId: marketId
            }
        })
    },
    async getProducts(marketId, categoryName, itemsPerPage, page) {
        return await API.get("/FilterSetItems/Details/", {
            params: {
                query: categoryName,
                marketId: marketId,
                pageSize: itemsPerPage,
                pageNumber: page,
            }
        })
    },
    async getProductsFiltered(marketId, categoryName, additionalParams, itemsPerPage, page) {
        return await API.get("/FilterSet/Details/?query=" + categoryName.replace("&", "%26") + additionalParams, {
            params: {
                marketid: marketId,
                pageSize: itemsPerPage,
                pageNumber: page
            }
        })
    },
    async getApplicationInformationBrands(productId) {
        return await API.get("/ApplicationSearchEmbed/Details?id=ApplicationInfo", {
            params: {
                custItem: productId,
                custId: '711442'
            }
        })
    },
    async getApplicationInformationParts(productId, brandId, searchFilter, pageNumber, pageCount) {
        return await API.get("/ApplicationSearchEmbed/Details?id=ApplicationDetails", {
            params: {
                custItem: productId,
                brandIds: brandId,
                searchFilter: searchFilter,
                pageNumber: pageNumber,
                pageCount: pageCount,
                custId: '711442'
            }
        })
    },

    async getEngineProductsNew(engineId, marketId) {
        return await NewAPI.get("/engine/getitems/" + engineId, {
            params: {
                id: engineId,
                marketId: marketId,
                //custId: '711442'
            }
        })
    },
    async getEngineProductsCategoriesNew(engineId, marketId) {
        return await NewAPI.get("/engine/getitemcats/" + engineId, {
            params: {
                id: engineId,
                marketId: marketId,
                //custId: '711442'
            }
        })
    },
    async getItemAdditionalInfo(itemId, custId) {
        return await NewAPI.get("/item/GetItemAdditionalInfo/" + itemId + "/" + custId, {
            params: {
                id: itemId,
                custId: custId
            }
        }) 
    },
    async getB2CInvLevels() {
        return await NewAPI.get("/inventory/GetB2CStock/", {
            params: {
                custId: '711442'
            }
        })
    },

    //Start Legacy - We need to remove these one day
    async getEngineProductsCategories(engineId, marketId) {
        return await API.get("/CategorySearch/Details/", {
            params: {
                id: engineId,
                marketId: marketId,
                custId: '711442'
            }
        })
    },
    async getEngineProducts(engineId, marketId) {
        return await API.get("/ProductSearch/Details/", {
            params: {
                id: engineId,
                marketId: marketId,
                custId: '711442'
            }
        })
    },

   //End Legacy
}