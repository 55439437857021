import React, { Fragment, useState, useEffect } from 'react';
import Loading from '../Loading';

import Product from '../Product'
import { useParams, useLocation, Link } from "react-router-dom";
import { Accordion, Card } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Pagination from "react-js-pagination";
import { CommonService } from '../../services';
import ProductDetailModal from '../../components/ProductDetailModal';

const OldEngineDetails = (props) => {
    const itemsPerPage = 10
    let { engineId } = useParams()
    const data = useLocation()

    const [marketId, setMarketId] = useState(data.state !== undefined ? data.state.marketId : null);
    const [brandName, setBrandName] = useState();
    const [modelNumber, setModelNumber] = useState();
    const [horsePower, setHorsePower] = useState();
    //const [brandId, setBrandId] = useState();
    const [yearStart, setYearStart] = useState();
    const [yearStop, setYearStop] = useState();
    const [stroke, setStroke] = useState();
    const [cylinders, setCylinders] = useState();
    const [liters, setLiters] = useState();
    const [cubicInchDisplacement, setCubicInchDisplacement] = useState();
    const [engineCC, setEngineCC] = useState();
    const [description, setDescription] = useState();
    const [serialStart, setSerialStart] = useState();
    const [serialStop, setSerialStop] = useState();
    const [categories, setCategories] = useState([]);
    const [rootCategories, setRootCategories] = useState([]);
    const [numberOfParts, setNumberOfParts] = useState(0);
    const [allEngineParts, setAllEngineParts] = useState([]);
    const [filteredParts, setFilteredParts] = useState([]);
    const [pagedParts, setPagedParts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [loadingProducts, setLoadingProducts] = useState(true);
    const [crankCapMetric, setCrankCapMetric] = useState();
    const [crankCapStandard, setCrankCapStandard] = useState();
    const [gearCapMetric, setGearCapMetric] = useState();
    const [gearCapStandard, setGearCapStandard] = useState();

    const [allInvLevels, setAllInvLevels] = useState([]);

    const [modalShow, setModalShow] = React.useState(false);
    const [modalItemId, setmodalItemId] = React.useState(0);

    const getStockLevel = (custItemSku) => {

        if (allInvLevels != null && allInvLevels.length > 0) {
            var invRec = allInvLevels.find((element) => {
                return element.skuNumber === custItemSku;
            });

            if (invRec != null) {
                return invRec.quantity;
            } else {
                return 0;
            }
        }

        return null;
    }

    const openModal = (itemId) =>{
        setmodalItemId(itemId);
        setModalShow(true);
    }

    useEffect(() => {
        CommonService.getEngineDetails(engineId)
            .then(response => {
                if (response.status === 200) {
                    const engine = response.data.Data[0];
                    setModelNumber(engine.modelNumber);
                    setBrandName(engine.brandName);
                    //setBrandId(engine.brandId);
                    setYearStart(engine.yearStart);
                    setYearStop(engine.yearStop);

                    if (engine.horsePower != 0) {
                        setHorsePower(engine.horsePower);
                    }

                    if (engine.stroke != 0) {
                        setStroke(engine.stroke);
                    }


                    if (engine.cylinders != 0) {
                        setCylinders(engine.cylinders);
                    }


                    if (engine.liters != 0) {
                        setLiters(engine.liters);
                    }


                    if (engine.cubicInchDisplacement != 0) {
                        setCubicInchDisplacement(engine.cubicInchDisplacement);
                    }


                    if (engine.engineCC != 0) {
                        setEngineCC(engine.engineCC);
                    }


                    setDescription(engine.description);


                    setSerialStart(engine.serialStart);


                    setSerialStop(engine.serialStop);

                    setLoading(false);
                    setCrankCapMetric(engine.crankCapMetric);
                    setCrankCapStandard(engine.crankCapStandard);
                    setGearCapMetric(engine.gearCapMetric);
                    setGearCapStandard(engine.gearCapStandard);

                    if (marketId === null) {
                        setMarketId(engine.marketId);
                    }

                }
            }).catch(function (error) {
                console.log(error);
            });

        CommonService.getEngineProductsCategoriesNew(engineId, marketId)
            .then(response => {
                if (response.status === 200) {
                    setLoadingCategories(false)
                    const categories = response.data.data
                    setCategories(categories)
                    const uniqueParentCategoryIds = [...new Set(categories.map(obj => obj.parentCategoryId))]
                    const rootCategories = [];
                    uniqueParentCategoryIds.forEach(value => {
                        rootCategories.push(categories.filter(item => item.parentCategoryId === value)[0])
                    })
                    setRootCategories(rootCategories)
                }
            }).catch(function (error) {
                console.log(error);
            });

        CommonService.getEngineProductsNew(engineId, marketId)
            .then(response => {
                if (response.status === 200) {
                    setLoadingProducts(false)
                    const items = response.data.data
                    setFilteredParts(items)
                    setAllEngineParts(items)
                    const numberOf = response.data.data.length
                    setNumberOfParts(numberOf);
                    const pagedParts = items.slice(0).slice(0, itemsPerPage)
                    setPagedParts(pagedParts)
                }
            }).catch(function (error) {
                console.log(error);
            });

        CommonService.getB2CInvLevels()
            .then(response => {
                if (response.status === 200) {
                    const invLevels = response.data.data;
                    setAllInvLevels(invLevels);
                    console.log("getB2CInvLevels");
                    //console.log(invLevels);

                }
            }).catch(function (error) {
                console.log(error);
            });

    }, [engineId])



    const filterBySubcategory = (categoryId, categoryName) => {
        setSelectedCategory(categoryName);
        const enginesFiltered = allEngineParts.filter(product => product.cat2Row === categoryId);
        setFilteredParts(enginesFiltered);
        setNumberOfParts(enginesFiltered.length);
        const pagedParts = enginesFiltered.slice(0).slice(0, itemsPerPage);
        setPagedParts(pagedParts)
    }

    const filterByText = (searchText) => {

        const enginesFiltered = allEngineParts.filter(product =>
            product.saleItem.toLowerCase().includes(searchText.toLowerCase()) ||
            product.cat1Name.toLowerCase().includes(searchText.toLowerCase()) ||
            product.cat2Name.toLowerCase().includes(searchText.toLowerCase()));


        setFilteredParts(enginesFiltered);
        setNumberOfParts(enginesFiltered.length);
        const pagedParts = enginesFiltered.slice(0).slice(0, itemsPerPage);
        setPagedParts(pagedParts)
    }

    const showAllParts = () => {
        setFilteredParts(allEngineParts);
        let offset = (page - 1) * itemsPerPage
        const pagedParts = allEngineParts.slice(offset).slice(0, itemsPerPage)
        setNumberOfParts(allEngineParts.length);
        setPagedParts(pagedParts)
        setSelectedCategory(null);
    }

    const goToPage = (page) => {
        setPage(page);
        let offset = (page - 1) * itemsPerPage
        const pagedParts = filteredParts.slice(offset).slice(0, itemsPerPage)
        setPagedParts(pagedParts)
    }

    const toggleActive = (id) => {
        const categories = [];
        rootCategories.forEach(category => {
            if (category.parentCategoryId === id) {
                if (category.active === undefined || category.active === false) {
                    category.active = true;
                } else {
                    category.active = false;
                }
            }
            categories.push(category);
        })
        setRootCategories(categories);
    }

    return (
        loading === false ?


            <div className="engine">
                <div className="engine-details">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 text-center">
                                {brandName ? <h1>{brandName}</h1> : null}
                                <h5>Engine Details</h5> <Link to="/results" className="result-item" >Back to Results</Link>
                            </div>
                            <div className="col">
                                <div className="all-details">
                                    <div className="row">
                                        <div className="col-md-6 pr-md-0">
                                            <table className="table table-sm table-fixed">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <span>Model #:</span>
                                                            {modelNumber}
                                                        </td>
                                                        <td rowSpan="3">
                                                            {crankCapMetric || crankCapStandard ?
                                                                <span className="cap-container">
                                                                    <span className="cap-body">
                                                                        Engine Oil<br />
                                                                        Capacity:<br />
                                                                        <em>{crankCapMetric}</em>
                                                                        <em>{crankCapStandard}</em>
                                                                    </span>
                                                                </span>
                                                                : null
                                                            }
                                                            {gearCapMetric || gearCapStandard ?
                                                                <span className="cap-container">
                                                                    <span className="cap-body">
                                                                        Gearcase<br />
                                                                        Capacity:<br />
                                                                        <em>{gearCapMetric}</em>
                                                                        <em>{gearCapStandard}</em>
                                                                    </span>
                                                                </span>
                                                                : null
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span>Years:</span>
                                                            {yearStart} - {yearStop}
                                                        </td>
                                                    </tr>

                                                    {serialStart || serialStop ?
                                                        <tr>
                                                            <td>
                                                                <span>Serial range:</span>
                                                                {serialStart} - {serialStop}
                                                            </td>
                                                        </tr>
                                                        : null}



                                                    <tr>
                                                        {horsePower ?
                                                            <td>
                                                                <span>HP:</span>
                                                                {horsePower}
                                                            </td>
                                                            : null}


                                                        {engineCC ?
                                                            <td>
                                                                <span>CC:</span>
                                                                {engineCC}
                                                            </td>
                                                            : null}
                                                    </tr>
                                                    <tr>
                                                        {stroke ?
                                                            <td>
                                                                <span>STROKE:</span>
                                                                {stroke}
                                                            </td>
                                                            : null}

                                                        {cylinders ?
                                                            <td>
                                                                <span>Cylinders:</span>
                                                                {cylinders}
                                                            </td>
                                                            : null}

                                                    </tr>
                                                    <tr>
                                                        {liters ?
                                                            <td>
                                                                <span>LITER:</span>
                                                                {liters}
                                                            </td>
                                                            : null}


                                                        {cubicInchDisplacement ?
                                                            <td>
                                                                <span>CID:</span>
                                                                {cubicInchDisplacement}
                                                            </td>
                                                            : null}

                                                    </tr>
                                                    {description ?
                                                        <tr>
                                                            <td colSpan="2">
                                                                <span>DESCRIPTION:</span>
                                                                {description}
                                                            </td>
                                                        </tr>
                                                        : null}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="engine-image">
                                                <img src="https://catalog.sierraparts.com/static/media/1.7ab2a475.png" alt={modelNumber} />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row justift-content-end">
                        <div className="col-md-4">
                            <aside>
                                {loadingProducts ? null :
                                    <div className="widget-title">
                                        <strong className="filter-title">PARTS FOR THIS ENGINE</strong> <span className="badge badge-secondary">{numberOfParts}</span>
                                    </div>
                                }
                                {loadingCategories ? <Loading /> :
                                    <Fragment>
                                        {loadingProducts || selectedCategory === null ? null : <button onClick={showAllParts} >View all parts</button>}
                                        {rootCategories.length > 0 ?
                                            rootCategories.map((rootCategory, index) =>
                                                <Accordion key={index} >
                                                    <Card>
                                                        <Card.Header className={rootCategory.active === true ? 'panel-wrap active-panel' : 'panel-wrap'}>
                                                            <Accordion.Toggle variant="link" eventKey={rootCategory.parentCategoryId} onClick={() => toggleActive(rootCategory.parentCategoryId)}>
                                                                <em><FontAwesomeIcon icon={faAngleRight} /></em>
                                                                <span>{rootCategory.parentCategory}</span>
                                                            </Accordion.Toggle>
                                                        </Card.Header>
                                                        <Accordion.Collapse eventKey={rootCategory.parentCategoryId}>
                                                            <Card.Body>
                                                                <ul>
                                                                    {categories.length > 0 ? categories.map((category, cindex) =>
                                                                        category.parentCategoryId === rootCategory.parentCategoryId ?
                                                                            <li key={cindex} >
                                                                                <button href="#" onClick={() => filterBySubcategory(category.childCategoryId, category.childCategory)} >
                                                                                    <span>{category.childCategory}</span>
                                                                                </button>
                                                                            </li>
                                                                            : null
                                                                    ) : null}
                                                                </ul>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>
                                            ) : null}
                                    </Fragment>
                                }
                            </aside>
                        </div>
                        <div className="col-md-8">
                            {loadingProducts ? <Loading /> :
                                <Fragment>
                                    <div className="row">
                                        <div className="filter-breadcrumbs">
                                            <span className="bc">All Parts</span>
                                            {selectedCategory ?
                                                <Fragment>
                                                    <span className="delimiter">/</span>
                                                    <span className="delimiter">{selectedCategory}</span>
                                                </Fragment>
                                                : null}
                                        </div>

                                        <div className="row justify-content-start no-gutters">
                                            <h2 className="col-auto pr-3">Parts</h2>
                                            <div className="col">
                                                <input className="form-control" type="text" name="filter" placeholder="Filter All Parts" onChange={event => filterByText(event.target.value)} />
                                            </div>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col">
                                                <div className="results">
                                                    {pagedParts.length > 0 ? pagedParts.map((product, index) =>
                                                        <Product
                                                            key={index}
                                                            categoryChild={product.cat2Name}
                                                            categoryParent={product.cat1Name}
                                                            saleItem={product.saleItem}
                                                            productId={product.itemRow}
                                                            imagePath={product.fileName}
                                                            custItem={product.custItem}
                                                            itemRow={product.itemRow}
                                                            stockLevel={product.custItem ? getStockLevel(product.custItem) : 0}
                                                            showModal={(id) => openModal(id)}
                                                            pCustId={props.pCustId}
                                                        />
                                                    ) : null}
                                                </div>
                                            </div>
                                            {numberOfParts > itemsPerPage ? (
                                                <div className="text-center mt-2">
                                                    <Pagination
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                        activePage={page}
                                                        itemsCountPerPage={itemsPerPage}
                                                        totalItemsCount={numberOfParts}
                                                        pageRangeDisplayed={5}
                                                        onChange={goToPage}
                                                        
                                                    />
                                                </div>
                                            ) : null}
                                        </div>

                                    </div>
                                </Fragment>
                            }
                        </div>
                    </div>
                </div>
                <div className=''>
                    <ProductDetailModal modalItemId={modalItemId} show={modalShow} closeModal={()=>setModalShow(false)} onHide={()=>setModalShow(false)}></ProductDetailModal>
                </div>
            </div>

            : <Loading />
    )
}

export default OldEngineDetails;
